import React from 'react'
import { graphql } from 'gatsby'
import orderBy from 'lodash/orderBy'
// import Helmet from 'react-helmet'
import moment from 'moment'
import NovaLayout from '../components/NovaLayout'
import Card from '../components/Card'
import CardList from '../components/CardList'
import Pagination from '../components/Pagination'
import Container from '../components/Container'
import { PageHeader } from '../components/PageHeader'
import PostPreview from '../components/PostPreview'



const TagTemplate = ({ data, pageContext }) => {
  const posts = orderBy(
    data.contentfulTag.post,
    // eslint-disable-next-line
    [object => new moment(object.publishDateISO)],
    ['desc']
  )

  const { title, slug } = data.contentfulTag
  // const numberOfPosts = posts.length
  const skip = pageContext.skip
  const limit = pageContext.limit
  const currentPage = pageContext.currentPage
  // const isFirstPage = currentPage === 1

  return (
    <NovaLayout title={`${title} Blog Posts | Nova Digital Agency`}  description="A digital agency designed for growth-driven mortgage businesses">
        {/* <Helmet>
          <title>{`Tag: ${title} - ${config.siteTitle}`}</title>
          <meta
            property="og:title"
            content={`Tag: ${title} - ${config.siteTitle}`}
          />
          <meta property="og:url" content={`${config.siteUrl}/tag/${slug}/`} />
        </Helmet> */}
      <PageHeader heading={`${title} Posts`} />
      <div className="layout-body"> 
        <div className="mt-4 xs-mt-2 resource-main-section-posts grid-equalHeight mt-2">
          {posts.slice(skip, limit * currentPage).map(post => (
            <PostPreview key={post.id} post={post} tag className="col-4_md-6_xs-12 mb-1" />
          ))}
        </div>
        {/* <CardList>
          {posts.slice(skip, limit * currentPage).map(post => (
            <Card {...post} key={post.id} />
          ))} 
        </CardList> */}
        <Pagination context={pageContext} />
      </div>
    </NovaLayout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulTag(slug: { eq: $slug }) {
      title
      id
      slug
      post {
        tags {
          title
          id
          slug
        }
        id
        title
        slug
        publishDate(formatString: "MMMM DD, YYYY")
        publishDateISO: publishDate(formatString: "YYYY-MM-DD")
        heroImage {
          title
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        bodyFirst {
          childMarkdownRemark {
            timeToRead
            html
            excerpt(pruneLength: 130)
          }
        }
        bodySecond {
          childMarkdownRemark {
            timeToRead
            html
            excerpt(pruneLength: 130)
          }
        }
      }
    }
  }
`

export default TagTemplate
