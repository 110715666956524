import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const Excerpt = styled.p`
  margin: 0 1rem 1rem 1rem;
  line-height: 1.6;
`

const Card = ({
  slug,
  heroImage,
  title,
  publishDate,
  bodyFirst,
  bodyFirst: {
    childMarkdownRemark: { timeToRead },
  },
  ...props
}) => {
  return (
      <Link to={`/blog/${slug}/`}>
        <Img fluid={heroImage.fluid} backgroundColor={'#eeeeee'} />
        <h4>{title}</h4>
        <h5>{publishDate}</h5>
        <p>{timeToRead} min read</p>
        <Excerpt
          dangerouslySetInnerHTML={{
            __html: bodyFirst.childMarkdownRemark.excerpt,
          }}
        />
      </Link>
  )
}

export default Card
